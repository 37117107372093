import Vue from 'vue'
import db from '@/firebase/init'
import _omit from 'lodash/omit'
import firebase from 'firebase'
import router from '@/router'

var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  project: null,
  pages: [],
  status: {
    getting: false,
    updating: false,
    error: false,
    progress: 0,
    deleting: false,
    uploading: false,
    deletingProject: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  /*------------------------------------------------------------------------------
   * MIDDLEWARE
   *----------------------------------------------------------------------------*/
  access: (state, getters, rootState) => {
    let access = 'checking'
    
    if (!firebase.auth().currentUser && state.project) {
      access = state.project.id == Vue.$cookies.get('project')
    }
    else if (rootState.user.user && (state.project || rootState.page.isPageTemplate)) {
      let user = rootState.user.user
      let project = state.project
      
      // ALLOW ACCESS FOR ADMIN USERS
      if (user.role == 'admin' || user.manageProjects) {
        access = true
      }
      // CHECK IF IS PAGE TEMPLATE
      else if (rootState.page.isPageTemplate && user.managePageTemplates) {
        access = true
      }
      // IF PROJECT IS A TEMPLATE
      else if (project && project.template && user.manageProjectTemplates) {
        access = true
      }
      // ALLOW ACCESS FOR PROJECT OWNER
      else if (project.owner == user.userid) {
        access = true
      }
      // ALLOW ACCESS FOR COLLABORATORS
      else if (rootState.collaborators.collaborators.length) {
        let collabs = rootState.collaborators.collaborators
        access = !!collabs.find(collab => collab.user == user.userid && collab.status == 'active')
      }
      else if (!state.status.getting) {
        access = false
      }
    }

    return access
  },

  /*------------------------------------------------------------------------------
   * IS OWNER
   *----------------------------------------------------------------------------*/
  isOwner: (state) => {
    let user = firebase.auth().currentUser
    return user && state.project && state.project.owner == user.uid
  },

  /*------------------------------------------------------------------------------
   * CAN MANAGE
   *----------------------------------------------------------------------------*/
  canManage: (state, getters, rootState) => {
    let user = rootState.user.user

    if (user) {
      if (user.role == 'admin') return true
      else return !!user.organization && user.manageProjects
    }
    else return false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setProject(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.project = data
    state.status.getting = false
  },

  updatingState(state, bol) {
    state.status.updating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  uploadProgress(state, progress) {
    state.status.progress = progress
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  updateProjectLogo(state, data) {
    state.project.logo = data
  },

  uploadingState(state, bol) {
    state.status.uploading = bol
  },

  resetProject(state) {
    state.project = null
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * METHODS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET PROJECT
   *----------------------------------------------------------------------------*/
  async getProject({ commit, dispatch }, id) {
    commit('gettingState', true)

    await db.collection('projects')
    .doc(id).get()
    .then(doc => {
      if (doc.exists) {
        commit('setProject', doc)
      }
      else {
        commit('gettingState', false)
        router.push({ name: 'PageNotFound' })
      }
    })
    .catch(error => {
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
      console.log('project.js', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE
   *----------------------------------------------------------------------------*/
  async update({ commit, dispatch }, settings) {
    commit('updatingState', true)
    settings.updatedAt = Date.now()
    let hasError = false

    if (settings.logo && typeof settings.logo == 'object') {
      await dispatch('uploadLogo', {
        file: settings.logo,
        project: settings
      })
    }
    
    await settings.ref.update(_omit(settings, ['ref', 'id', 'logo']))
    .then(() => {
      commit('updatingState', false)
      dispatch('showSuccess', 'Project udpated', { root: true })
      commit('setProject', settings)
    })
    .catch(error => {
      commit('updatingState', false)
      commit('setError', error.message)
      console.log(error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * UPLOAD PROJECT LOGO
   *----------------------------------------------------------------------------*/
  async uploadLogo({ commit, dispatch }, data) {
    commit('uploadingState', false)
    let file = data.file
    let project = data.project

    let name = `${Date.now()}_${project.id}_${file.name}`

    var metadata = {
      contentType: file.type
    }

    var uploadTask  = storageRef.child(`logos/${name}`).put(file, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('uploadProgress', progress)
    }, error => {
      dispatch('showError', error.message, { root: true })
      console.log('project.js', error.message)
    }, () => {
      project.ref.update({ logo: name })
      .then(() => {
        commit('uploadProgress', 0)
        commit('updateProjectLogo', name)
        commit('uploadingState', false)
        commit('projects/updateProjectLogo', {
          name,
          id: project.id
        }, { root: true })
      })
      .catch(error => {
        commit('uploadProgress', 0)
        dispatch('showError', error.message, { root: true })
        commit('uploadingState', false)
        console.log('project.js', error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE LOGO
   *----------------------------------------------------------------------------*/
  async deleteProjectLogo({ state, commit, dispatch }) {
    commit('deletingState', true)
    let logo = state.project.logo
    let ext = Vue.prototype.$getFileExtension(logo)    

    await storageRef.child(`logos/${logo}`).delete()
    .then(async () => {
      if (ext !== 'svg') {
        await storageRef.child(`logos/thumb_${logo}`).delete()
        await storageRef.child(`logos/medium_${logo}`).delete()
        await storageRef.child(`logos/large_${logo}`).delete()
      }

      await state.project.ref.update({ logo: null })
      .then(() => {
        commit('updateProjectLogo', null)
        commit('deletingState', false)
      })
    })
    .catch(error => {
      console.log('project.js', error.message)
      dispatch('showError', error.message, { root: true })
      commit('deletingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE PROJECT
   *----------------------------------------------------------------------------*/
  async deleteProject({ state, commit, dispatch }) {
    commit('updateStatus', { deletingProject: true })
    
    await state.project.ref.delete()
    .then(() => {
      commit('updateStatus', { deletingProject: false })
      dispatch('showSuccess', 'Project deleted', { root: true })
      commit('projects/removeProject', state.project, { root: true })
      commit('allProjects/removeProject', state.project, { root: true })
    })
    .catch(error => {
      commit('updateStatus', { deletingProject: false })
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PROJECT USER
   *----------------------------------------------------------------------------*/
  updateUser({ state, commit, dispatch }) {
    let user = firebase.auth().currentUser

    if (user) {
      state.project.ref.update({ owner: user.uid, folder: 'root' })
      .then(() => {
        let data = state.project
        data.owner = user.uid
        commit('setProject', data)
        Vue.$cookies.remove('project')
      })
      .catch(error => {
        console.log('updateUser:', error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE PROJECT NAME
   *----------------------------------------------------------------------------*/
  updateProjectName({ state, commit, dispatch }, name) {
    commit('updateStatus', { updating: true })
    
    state.project.ref
    .update({ name })
    .then(() => {
      state.project.name = name

      commit('updateStatus', { updating: false })
      commit('projects/updateProject', state.project, { root: true })
      dispatch('showSuccess', 'Project name updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
