<template>
  <v-app id="builder">
    <slot name="adminDrawer"></slot>

    <!-- DASHBOARD NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-if="dashboardDrawer() || $route.meta.showDrawer"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      :width="$drawer.small"
      color="primary"
      app
    >
      <admin-menu />

      <template #append>
        <Copyright />
      </template>
    </v-navigation-drawer>

    <!-- PROJECT NAVIGATION DRAWER -->
    <template v-if="$store.getters['project/access'] === true">
      <v-navigation-drawer
        v-if="$route.name == 'Project'"
        v-show="!$store.state.preview"
        v-model="projectDrawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :width="$drawer.medium"
        color="primary"
        permanent
        app
      >
        <project-menu />

        <template #append>
          <Copyright />
        </template>
      </v-navigation-drawer>

      <v-navigation-drawer
        v-if="$route.name == 'Project'"
        v-show="!$store.state.preview"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :width="$drawer.medium"
        color="primary"
        permanent
        right
        app
      >
        <ProjectMenuRight />
      </v-navigation-drawer>
    </template>

    <!-- NAVIGATION DRAWER -->
    <v-navigation-drawer
      :value="$store.state.blocks.status.uploading"
      :clipped="$vuetify.breakpoint.lgAndUp"
      class="top-drawer"
      :width="$drawer.medium"
      hide-overlay
      floating
      temporary
      fixed
      stateless
      right
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <Uploading />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <!-- MOBILE NAVIGATION DRAWER -->

    <v-navigation-drawer
      :width="$drawer.medium"
      v-model="mobileDrawer"
      color="primary"
      class="pa-3"
      app
    >
      <admin-menu />
      
      <v-card v-if="!user" outlined>
        <v-list>
          <v-list-item
            @click="
              $store.commit('user/setShowDialog', true)
              $store.commit('user/setActionType', 'login')
            "
            dense
          >
            <v-list-item-icon class="mr-2">
              <v-icon xs>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="
              $store.commit('user/setShowDialog', true)
              $store.commit('user/setActionType', 'signup')
            "
            dense
          >
            <v-list-item-icon class="mr-2">
              <v-icon xs>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Signup</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-navigation-drawer>

    <!-- APP BAR -->
    <v-app-bar
      v-show="!$store.state.preview && !$route.meta.hideHeader"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      class="topnav-shadow"
      color="white"
      app
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-none"></v-app-bar-nav-icon> -->
      <router-link :to="{ name: 'Home' }" class="hidden-sm-and-dow text-decoration-none font-weight-bold">
        <v-img 
          :src="require('@/assets/Lightning_Builder_Logo.svg')"
          :width="$vuetify.breakpoint.mdAndUp ? '200' : '150'"
          contain
          left
        ></v-img>
      </router-link>

      <!-- MENU BUTTON -->
      <v-btn @click="mobileDrawer = true" v-show="$vuetify.breakpoint.mdAndDown" icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
  
      <template v-if="!user && $route.name !== 'OrderPage'">
        <v-btn
          v-show="$vuetify.breakpoint.mdAndUp"
          @click="
            $store.commit('user/setShowDialog', true)
            $store.commit('user/setActionType', 'login')
          "
          text
        >
          <span class="text-none body-1 font-weight-bold">Login</span>
        </v-btn>

        <v-divider v-show="$vuetify.breakpoint.mdAndUp" vertical class="mx-2"></v-divider>

        <v-btn
          v-show="$vuetify.breakpoint.mdAndUp"
          @click="
            $store.commit('user/setShowDialog', true)
            $store.commit('user/setActionType', 'signup')
          "
          color="primary"
          text
        >
          <span class="text-none body-1 font-weight-bold">Sign Up Today</span>
        </v-btn>
      </template>

      <div v-if="user" class="d-inline mr-3">
        <Notifications />
      </div>

      <v-menu
        v-if="user"
        transition="slide-y-transition"
        offset-y
        left
      >
        <template #activator="{ on }">
          <v-card class="mr-2" flat>
            <v-list-item class="px-1" v-on="on" dense>
              <v-list-item-avatar class="my-1 mr-4 overflow-visible" size="30" rounded>
                <UserPhoto :size="30" photoSize="thumb" :id="user.userid" rounded />
              </v-list-item-avatar>
              <v-list-item-title class="d-none d-md-block secondary--text">{{ user.fullName }}</v-list-item-title>
              <v-list-item-icon class="d-none d-md-block secondary--text">
                <v-icon small>mdi-chevron-down</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-card>
        </template>
        <v-list dense outlined>
          <v-list-item :to="{ name: 'AccountSettings' }">
            <v-list-item-title><v-icon small left>mdi-account-cog-outline</v-icon> Account Settings</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signout()">
            <v-list-item-title><v-icon small left>mdi-logout-variant</v-icon> Signout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        :to="{ name: 'Admin' }"
        v-if="user && user.role == 'admin'"
        color="primary"
        depressed
        exact
        small
      >Admin</v-btn>

      <v-btn
        :to="{ name: 'Projects' }"
        v-if="user && user.role !== 'admin'"
        color="primary"
        depressed
        exact
        small
      >Dashboard</v-btn>
    </v-app-bar>
    <!-- END APP BAR -->

    <!-- CONTENT -->
    <v-main :class="{ 'pa-0' : $store.state.preview || $route.meta.hideHeader }">
      <v-container
        :class="$route.name !== 'Home' && !$route.meta.resetPadding ? 'pa-12' : 'pa-0'"
        :style="{ background: $route.name == 'Project' && !$store.state.preview ? `url('/images/pattern.png') fixed` : '' }"
        style="height: 100%"
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- LOGIN -->
    <Login />

    <!-- SUCCESS -->
    <success />

    <!-- ERROR -->
    <error />

    <!-- UPDATING DIALOG -->
    <updating />

    <!-- GLOBAL IMAGE DIALOG PREVIEW -->
    <v-dialog
      v-model="$store.state.showImageDialog"
      no-click-animation
      max-width="1024"
      persistent
      scrollable
    >
      <close-btn overflow @click="$store.commit('closeImagePreview')" />
      
      <v-card>
        <v-card-text class="pa-0">
          <v-img :src="$store.state.imageUrl" contain />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer v-if="false" :app="$route.name !== 'Home'">
      <div class="grey--text caption">
        &copy; 2020 Lightning Builder by <a href="https://www.lightningsites.com/" target="_blank" class="text-decoration-none">Lightning Sites</a>
      </div>
      <v-spacer></v-spacer>
      <div class="grey--text caption">Version 1.0</div>
    </v-footer>
  </v-app>
</template>
<script>
  import firebase from 'firebase'
  import { mapState, mapActions } from 'vuex'

  import Login from './components/Login'
  import error from './components/ErrorSnackbar'
  import AdminMenu from './components/AdminMenu'
  import Uploading from './components/Uploading'
  import Copyright from './components/Copyright'
  import success from './components/SuccessSnackbar'
  import Updating from './components/UpdatingDialog'
  import ProjectMenu from './views/project/ProjectMenu'
  import ProjectMenuRight from './views/project/ProjectMenuRight'
  import Notifications from './views/notifications/components/Notifications'

  export default {

    metaInfo: {
      title: 'Lightning Page Builder',
      titleTemplate: '%s | Lightning Page Builder'
    },

    components: {
      error,
      Login,
      success,
      Updating,
      AdminMenu,
      Uploading,
      Copyright,
      ProjectMenu,
      Notifications,
      ProjectMenuRight
    },

    props: {
      source: String,
    },

    /*------------------------------------------------------------------------------
     * DATA
     *----------------------------------------------------------------------------*/
    data: () => ({
      drawer: null,
      uploading: true,
      showLogin: false,
      projectDrawer: null,
      mobileDrawer: false,
    }),

    /*------------------------------------------------------------------------------
     * COMPUTED
     *----------------------------------------------------------------------------*/
    computed: {
      ...mapState({
        user: state => state.user.user,
        view: state => state.view
      })
    },

    /*------------------------------------------------------------------------------
     * METHODS
     *----------------------------------------------------------------------------*/
    methods: {
      ...mapActions('user', [
        'setUser'
      ]),
      
      ...mapActions('generalSettings', [
        'getSettings'
      ]),

      signout() {
        firebase.auth().signOut()
        .then(() => {
          this.$store.commit('user/clearUser')
          if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          console.log(error.message)
        })
      },

      dashboardDrawer() {
        let showDrawer = [
          'Admin',
          'Panels',
          'Folder',
          'Orders',
          'Request',
          'Requests',
          'Projects',
          'OrderForms',
          'RequestType',
          'AllProjects',
          'RequestTypes',
          'OpenRequests',
          'BrandProfiles',
          'PageTemplates',
          'CreateRequest',
          'CreteOrderForm',
          'PausedRequests',
          'GeneralSettings',
          'AccountSettings',
          'EditBrandProfile',
          'ProjectTemplates',
          'AssignedRequests',
          'DeliveredRequests',
          'ProductFormBuilder',
          'InProgressRequests',
        ]

        return showDrawer.includes(this.$route.name)
      },
    },

    created() {
      firebase.auth().onAuthStateChanged(() => {
        if (firebase.auth().currentUser) {
          this.setUser()
        }
      })
    
      this.getSettings()
    }

  }
</script>

<style lang="scss">
  .v-overlay.v-overlay--active > .v-overlay__scrim {
    opacity: 0.8 !important;
    background-color: var(--v-secondary-base) !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
</style>
