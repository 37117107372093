import db from '@/firebase/init'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  settings: {},
  status: {
    saving: false,
    getting: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  pageBanner: (state) => {
    if (state.settings) {
      return state.settings.pageBanner || 10000000000
    }
  },
  
  maxProjects: (state) => {
    if (state.settings) {
      return state.settings.maxProjects || 3
    }
  },
  
  maxClientProjects: (state) => {
    if (state.settings) {
      return state.settings.maxClientProjects || 3
    }
  },

  allowedActiveRequests: (state) => {
    if (state.settings) {
      return state.settings.allowedActiveRequests || 5
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  savingState(state, bol) {
    state.status.saving = bol
  },

  gettingState(state, bol) {
    state.status.getting = bol
  },

  updateSettings(state, payload) {
    payload.forEach(doc => {
      Vue.set(state.settings, doc.id, doc.data().value)
    })
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * SAVE SETTINGS
   *----------------------------------------------------------------------------*/
  saveSettings({ state, commit, dispatch }) {
    commit('savingState', true)
    const batch = db.batch()
    
    Object.keys(state.settings)
    .forEach(key => {
      let docRef = db.collection('generalSettings').doc(key)
      batch.set(docRef, { value: state.settings[key] })
    })
    
    batch.commit()
    .then(() => {
      commit('savingState', false)
      dispatch('showSuccess', 'Settings saved', { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET SETTINGS
   *----------------------------------------------------------------------------*/
  getSettings({ commit }) {
    commit('gettingState', true)
    
    db.collection('generalSettings')
    .get()
    .then((snapshot) => {
      if (snapshot.size) commit('updateSettings', snapshot)
      commit('gettingState', false)
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}