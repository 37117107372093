import Vue from 'vue'
import _camelCase from 'lodash/camelCase'
import _kebabCase from 'lodash/kebabCase'
import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'

var storageRef = firebase.storage().ref()

Vue.prototype.$classes = {
  drawerTitle: 'mb-5 subtitle-1 font-weight-bold primary--text',
}

Vue.prototype.$drawer = {
  small: '250',
  medium: '300',
  large: '450',
}

Vue.prototype.$dialog = {
  large: '1200',
  medium: '770',
  small: '450',
  content: '570'
}

Vue.prototype.$window = window

Vue.prototype.$formatData = (payload) => {
  let data = payload.data()
  data.id = payload.id
  data.ref = payload.ref
  return data
}

Vue.prototype.$colors = {
  panelHeader: 'rgba(147,222,251,0.1)',
  panelContent: '#222c80',
}

Vue.prototype.$shortcodes = (block, section) => {
  let shortcodeIndex = _camelCase(`${section.orientation}-${section.color}-shortcode`)

  let data = {
    type: section.type,
    data: block[shortcodeIndex],
    order: section.order,
    id: section.id,
  }

  return data
}

Vue.prototype.$cssCodes = (block, section) => {
  let cssCodeIndex = _camelCase(`${section.orientation}-${section.color}-css`)

  let data = {
    type: section.type,
    data: block[cssCodeIndex],
    order: section.order,
    color: section.color,
    id: section.id,
    name: block.name,
    identifier: _kebabCase(`${block.name} ${section.color}`)
  }
  
  return data
}

Vue.prototype.$getBlock = async (id) => {
  let block = null
  
  await db.collection('blocks')
  .doc(id).get()
  .then(doc => {
    if (doc.exists) {
      block = doc.data()
      block.id = doc.id
      block.ref = doc.ref
    }
  })
  .catch(error => {
    console.log(error.message)
  })

  return block
}

Vue.prototype.$getUrl = async (link) => {
  var storage  = firebase.storage()
  let returnUrl = ''

  await storage.ref(link).getDownloadURL()
  .then(url => {
    returnUrl = url
  })
  .catch(error => {
    console.log(error.message)
  })

  return returnUrl
}

Vue.prototype.$getFileExtension = (file) => {
  let data = file.split('.')
  return data[data.length - 1]
}

Vue.prototype.$downloadFile = (folder, file) => {
  storageRef.child(`${folder}/${file}`).getDownloadURL()
  .then(function(url) {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = function() {
      var blob = xhr.response
      var a = document.createElement('a')
      if (a) {
        a.href = window.URL.createObjectURL(blob)
        a.download = file
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }

    xhr.open('GET', url)
    xhr.send()

  }).catch(function(error) {
    console.log(error.message)
  })
  
}

Vue.prototype.$getBlob = async (folder, file) => {
  var blob = null
  
  await storageRef.child(`${folder}/${file}`).getDownloadURL()
  .then((url) => {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.onload = function() {
      blob = xhr.response
    }

    xhr.open('GET', url)
    xhr.send()
  }).catch(function(error) {
    console.log(error.message)
  })

  return blob
}

Vue.prototype.$fileIcon = (type, color = false) => {
  let icons = [
    {
      icon: 'mdi-file-image-outline',
      color: 'green accent-4',
      types: [
        'image/jpeg',
        'image/png'
      ]
    },
    {
      icon: 'mdi-file-pdf-outline',
      color: 'red accent-3',
      types: [
        'application/pdf',
      ]
    },
    {
      icon: 'mdi-file-code-outline',
      color: 'amber darken-2',
      types: [
        'image/svg+xml',
      ]
    },
    {
      icon: 'mdi-zip-box-outline',
      color: 'blue-grey darken-1',
      types: [
        'application/x-zip-compressed',
      ]
    },
    {
      icon: 'mdi-zip-box-outline',
      color: 'blue-grey darken-1',
      types: [
        'application/x-zip-compressed',
      ]
    },
  ]

  let icon = icons.find(i => {
    return i.types.includes(type)
  })

  if (color) return icon ? icon.color : 'blue accent-3'
  else return icon ? icon.icon : 'mdi-file-document-outline'
}

Vue.prototype.$overdue = (request) => {
  if (request.dueDate) {
    return moment(request.dueDate.toDate()).diff(moment(), 'hours') <= 0 && !['completed', 'pending_review'].includes(request.status)
  }
  else {
    return false
  }
}

Vue.prototype.$sanitize = {
  allowedTags: [
    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "oembed",
    "iframe", "img"
  ],
  disallowedTagsMode: 'recursiveEscape',
  allowedAttributes: {
    'strong': ['class', 'data-mention', 'data-user-id'],
    'a': ['href'],
    'figure': ['class', 'style'],
    'oembed': ['url'],
    'iframe': ['src'],
    'div': ['data-oembed-url'],
    'img': ['src', 'class'],
  },
  allowedSchemesByTag: {
    img: [ 'data' ]
  }
}