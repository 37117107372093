import Vue from 'vue'
import db from '@/firebase/init'
import firebase from 'firebase'
import _ from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

 const state = {
   user: null,
   data: {},
   password: {},
   status: {
     error: null,
     action: 'login',
     updating: false,
     signingUp: false,
     signingIn: false,
     resetting: false,
     showDialog: false,
     uploadProgress: 0,
     uploadError: null,
     confirmError: null,
     passwordError: null,
     uploadingPhoto: false,
     reauthenticating: false,
     accountConfirmed: false,
     uploadPhotoDialog: false,
     validatingPassword: false,
     signingInWithGoogle: false,
     showConfirmPassword: false,
   }
 }

 /*------------------------------------------------------------------------------
  * GETTERS
  *----------------------------------------------------------------------------*/
 const getters = {
   provider: () => {
     let user = firebase.auth().currentUser
     if (user.providerData[0].providerId == 'google.com') return 'Google'
     else if (user.providerData[0].providerId == 'facebook.com') return 'Facebook'
     else return 'Email and Password'
   },

   role: (state) => {
     let role = 'guest'

     if (state.user) {
       role = state.user.role
     }

     return role
   },
   
   isAdmin: (state) => {
     if (state.user) {
       return state.user.role == 'admin'
     }
     else return false
   },
   
   isClient: (state) => {
     if (state.user) {
       return state.user.role == 'client'
     }
     else return false
   },
   
   isEmployee: (state) => {
     if (state.user) {
       return state.user.organization || state.user.role === 'admin'
     }
     else return false
   },

   data: (state) => {
    return state.user || {}
   }
 }

 /*------------------------------------------------------------------------------
  * MUTATIONS
  *----------------------------------------------------------------------------*/
const mutations = {
  setSigningUp(state, bol) {
    state.status.signingUp = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  setData(state, payload) {
    state.data = payload
  },

  clearData(state) {
    state.data = {}
  },

  setSigningIn(state, bol) {
    state.status.signingIn = bol
  },

  setUserData(state, payload) {
    let data

    try {
      data = payload.data()
      data.ref = payload.ref
    }
    catch {
      data = payload
    }

    state.user = data
  },

  setShowDialog(state, bol) {
    state.status.showDialog = bol
  },

  clearUser(state) {
    state.user = null
  },

  setSigningInWithGoogle(state, bol) {
    state.status.signingInWithGoogle = bol
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setShowConfirmPassword(state, bol) {
    state.status.showConfirmPassword = bol
  },

  setReauthenticatingState(state, bol) {
    state.status.reauthenticating = bol
  },

  setAccountConfirmed(state, bol) {
    state.status.accountConfirmed = bol
  },

  setConfirmError(state, message) {
    state.status.confirmError = message
  },

  setValidatingPassword(state, bol) {
    state.status.validatingPassword = bol
  },

  setPasswordError(state, message) {
    state.status.passwordError = message
  },

  clearPassword(state) {
    state.data.password = null
  },

  setResettingState(state, bol) {
    state.status.resetting = bol
  },

  setUploadingPhoto(state, bol) {
    state.status.uploadingPhoto = bol
  },

  setUploadProgress(state, progress) {
    state.status.uploadProgress = progress
  },

  setUploadError(state, error) {
    state.status.uploadError = error
  },

  setUploadPhotoDialog(state, bol) {
    state.status.uploadPhotoDialog = bol
  },

  resetUploadState(state) {
    state.status.uploadProgress = 0
    state.data.avatar = null
  },

  updateUserAvatarName(state, name) {
    state.user.avatar = name
  },

  setActionType(state, type) {
    state.status.action = type
  },

  showDemoState(state, bol) {
    if (state.user) state.user.showDemo = bol
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
   /*------------------------------------------------------------------------------
    * SIGNUP
    *
    * @params
    * none
    *----------------------------------------------------------------------------*/
  async signup({ state, commit, dispatch }) {
    commit('setSigningUp', true)
    const auth = firebase.auth()

    await auth.createUserWithEmailAndPassword(
      state.data.email, state.data.password
    ).then(response => {

      let data = {
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
        requestPackage: state.data.package || null,
        role: state.data.role || 'user',
        fullName: state.data.fullName,
        userid: response.user.uid,
        email: state.data.email,
        organization: false,
        showDemo: true,
        avatar: null,
      }

      let taskRef = db.collection('users').doc(response.user.uid)

      firebase.auth().onAuthStateChanged(async () => {
        let user = firebase.auth().currentUser

        if (user) {
          await db.collection('users')
          .where('email', '==', data.email)
          .limit(1).get()
          .then(async (snapshot) => {
            if (snapshot.size) {
              let doc = snapshot.docs[0]
              
              if (doc.id !== response.user.uid) {
                let userData = doc.data()
                userData.userid = response.user.uid
                userData.createdAt = firebase.firestore.Timestamp.now(),
                userData.updatedAt = firebase.firestore.Timestamp.now(),
                taskRef = taskRef.set(userData)
                await doc.ref.delete()
              }
            }
            else {
              taskRef = taskRef.set(data)
            }
          })

          taskRef.then(() => {
            commit('setSigningUp', false)
            commit('setShowDialog', false)
            commit('clearData')
            dispatch('setUser')
          })
          .catch(error => {
            console.log(error.message)
            commit('setError', error.message)
          })
        }
      })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setSigningUp', false)
    })
  },

   /*------------------------------------------------------------------------------
    * SIGN IN
    *
    * @params
    * none
    *----------------------------------------------------------------------------*/
   async signin({ commit, state }) {
     commit('setSigningIn', true)
     commit('setError', null)

     await firebase.auth()
     .signInWithEmailAndPassword(state.data.email, state.data.password)
     .then(() => {
       commit('setSigningIn', false)
       commit('setShowDialog', false)
       commit('clearData')
     })
     .catch(error => {
       commit('setError', error.message)
       commit('setSigningIn', false)
       commit('clearPassword')
     })
   },

   /*------------------------------------------------------------------------------
    * SET CURRENT USER
    *----------------------------------------------------------------------------*/
  setUser({ commit, dispatch }) {
    let currentUser = firebase.auth().currentUser

    db.collection('users')
    .where('userid', '==', currentUser.uid)
    .limit(1)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        commit('setUserData', doc)
        
        if (doc.data().showDemo == undefined) {
          doc.ref.update({ showDemo: false })
          commit('showDemoState', false) 
        }

        if (Vue.$cookies.get('project')) {
          dispatch('project/updateUser', null, { root: true })
        }

        // GET USER AND ADD 
        // TO USERS COLLECTION
        dispatch('users/getUserUid', currentUser.uid, { root: true })
      }
    })
    .catch(error => {
      commit('setError', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SIGN IN WITH GOOGLE
   *----------------------------------------------------------------------------*/
  socialSignin({ commit, dispatch }, type) {
    var provider = type == 'google' ? new firebase.auth.GoogleAuthProvider() : new firebase.auth.FacebookAuthProvider()
    commit('setSigningIn', true)
    commit('setSigningUp', true)
    commit('setError', null)

    firebase.auth().signInWithPopup(provider)
    .then(result => {
      var user = result.user

      let data = {
        userid: user.uid,
        email: user.email,
        fullName: user.displayName,
        role: 'user',
        avatar: null,
        organization: false,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
        showDemo: true,
      }

      let taskRef = db.collection('users').doc(user.uid)

      db.collection('users')
      .where('email', '==', data.email)
      .get()
      .then(async (snapshot) => {
        if (snapshot.size) {
          let doc = snapshot.docs[0]
          
          if (doc.id !== user.uid) {
            let userData = doc.data()
            userData.userid = user.uid
            userData.createdAt = firebase.firestore.Timestamp.now(),
            userData.updatedAt = firebase.firestore.Timestamp.now(),
            taskRef = taskRef.set(userData)
            await doc.ref.delete()
          }
          else {
            dispatch('resetLogin')
            return;
          }
        }
        else {
          taskRef = taskRef.set(data)
        }

        taskRef.then(() => {
          dispatch('resetLogin')
        })
      })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setSigningIn', false)
      commit('setSigningUp', false)
      commit('clearData')
    })

  },

  /*------------------------------------------------------------------------------
   * RESET LOGIN
   *----------------------------------------------------------------------------*/
  resetLogin({ commit, dispatch }) {
    commit('setSigningIn', false)
    commit('setSigningUp', false)
    commit('setShowDialog', false)
    commit('clearData')
    dispatch('setUser')
  },

  /*------------------------------------------------------------------------------
   * UPDATE PROFILE
   *----------------------------------------------------------------------------*/
  updateProfile({ state, dispatch, commit }) {
    let user = firebase.auth().currentUser
    commit('setUpdatingState', true)

    state.user.ref.update(_.pick(state.user, ['fullName']))
    .then(() => {
      if (state.user.email !== user.email) {
        user.updateEmail(state.user.email)
        .then(() => {
          state.user.ref.update(_.pick(state.user, ['email']))
          dispatch('showSuccess', 'Profile successfully updated.', { root: true })
          commit('setUpdatingState', false)
        })
      }
      else {
        dispatch('showSuccess', 'Profile successfully updated.', { root: true })
        commit('setUpdatingState', false)
      }
    })
    .catch(error => {
      commit('setUpdatingState', false)
      commit('setError', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * REVALIDATE CURRENT PASSWORD
   *----------------------------------------------------------------------------*/
   async reauthenticate({ state, commit, dispatch }, password = null) {
     if (state.status.confirmError) commit('setConfirmError', null)
     commit('setReauthenticatingState', true)

     let user = firebase.auth().currentUser
     let credential = firebase.auth.EmailAuthProvider.credential(user.email, password)

     await user.reauthenticateWithCredential(credential)
     .then(() => {
        commit('setAccountConfirmed', true)
        commit('setShowConfirmPassword', false)
        commit('setReauthenticatingState', false)
        dispatch('showSuccess', 'Account authenticated', { root: true })
     })
     .catch(error => {
        commit('setConfirmError', error.message)
        commit('setReauthenticatingState', false)
     })

   },

   /*------------------------------------------------------------------------------
    * UPDATE PASSWORD
    *----------------------------------------------------------------------------*/
   async updatePassword({ state, commit, dispatch }) {
     commit('setValidatingPassword', true)
     if (state.status.passwordError) commit('setPasswordError', null)

     let user = firebase.auth().currentUser
     let credential = firebase.auth.EmailAuthProvider.credential(user.email, state.password.current)

     await user.reauthenticateWithCredential(credential)
     .then(() => {
        return user.updatePassword(state.password.new)
        .then(() => {
          commit('setValidatingPassword', false)
          commit('show')
          dispatch('showSuccess', 'Password successfully updated.', { root: true })
        })
     })
     .catch(error => {
        commit('setPasswordError', error.message)
        commit('setValidatingPassword', false)
     })

   },

   /*------------------------------------------------------------------------------
    * RESET PASSWORD
    *----------------------------------------------------------------------------*/
   resetPassword({ state, commit, dispatch }) {
      commit('setResettingState', true)
      if (state.status.error) commit('setError', null)

      firebase
        .auth()
        .sendPasswordResetEmail(state.data.email)
        .then(() => {
          dispatch('showSuccess', 'Email successfully sent.', { root: true })
          commit('setShowDialog', false)
          commit('setResettingState', false)
          commit('clearData')
        }).catch(error => {
          commit('setError', error.message)
          commit('setResettingState', false)
        })
   },

   /*------------------------------------------------------------------------------
   * CONFIRM RESET PASSWORD
   *----------------------------------------------------------------------------*/
  confirmResetPassword({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('updateStatus', { updating: true })
      
      firebase.auth()
      .confirmPasswordReset(data.code, data.password)
      .then(() => {
        commit('updateStatus', { updating: false })
        dispatch('showSuccess', 'Password updated.', { root: true })
        resolve('Password reset successful')
      })
      .catch(error => {
        commit('updateStatus', { updating: false })
        dispatch('showError', error.message, { root: true })
        reject(error.message)
      })
    })
    
  },

   /*------------------------------------------------------------------------------
    * UPLOAD PHOTO
    *----------------------------------------------------------------------------*/
  async uploadPhoto({ commit, state, dispatch }) {
    commit('setUploadingPhoto', true)
    var storageRef = firebase.storage().ref()
    let name = `${Date.now()}_${state.data.avatar.name}`

    var metadata = {
      contentType: state.data.avatar.type
    }

    var uploadTask  = storageRef.child(`profilephotos/${name}`).put(state.data.avatar, metadata)

      await uploadTask.on('state_changed', snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        commit('setUploadProgress', progress)
      }, error => {
        commit('setUploadError', error.message)
      }, () => {
        commit('setUploadingPhoto', false)

        if (state.user.avatar) {
          storageRef.child(`profilephotos/${state.user.avatar}`).delete().catch(error => console.log(error.message))
          storageRef.child(`profilephotos/thumb_${state.user.avatar}`).delete().catch(error => console.log(error.message))
          storageRef.child(`profilephotos/medium_${state.user.avatar}`).delete().catch(error => console.log(error.message))
          storageRef.child(`profilephotos/large_${state.user.avatar}`).delete().catch(error => console.log(error.message))
        }

        state.user.ref.update({
          avatar: name
        })
        .then(() => {
          dispatch('showSuccess', 'Profile photo successfully updated.', { root: true })
          commit('resetUploadState')
          commit('setUploadPhotoDialog', false)
          commit('updateUserAvatarName', name)
        })
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE SHOW DEMO
   *----------------------------------------------------------------------------*/
  updateShowDemo({ state, commit }, bol) {
    if (state.user) {
      state.user.ref
      .update({ showDemo: bol })
      .then(() => {
        commit('showDemoState', bol)  
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  }

 }

 export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
 }
