import db from '@/firebase/init'
import _omit from 'lodash/omit'
import firebase from 'firebase'
import Vue from 'vue'

var storageRef = firebase.storage().ref()

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  types: [],
  images: [],
  product: {},
  products: [],
  status: {
    error: null,
    image: null,
    progress: 0,
    getting: false,
    creating: false,
    deleting: false,
    updating: false,
    firstLoad: false,
    uploading: false,
    deletingImage: false,
    creatingProduct: false,
    gettingProducts: false,
    firstLoadAllProducts: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getType: (state) => (id) => {
    if (state.types) {
      return state.types.find(type => type.id == id) || {}
    }
  },
  
  getProduct: (state) => (id) => {
    if (state.products) {
      return state.products.find(product => product.id == id) || {}
    }
  },

  getTypeName: (state) => (id) => {
    if (state.types) {
      let type = state.types.find(type => type.id == id)
      return type ? type.name : ''
    }
  },
  
  getProductName: (state) => (id) => {
    if (state.products) {
      let product = state.products.find(product => product.id == id)
      return product ? product.name : ''
    }
  },

  image: (state) => (id) => {
    if (state.images) {
      return state.images.find(i => i.id == id)
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  creatingState(state, bol) {
    state.status.creating = bol
  },

  errorState(state, message) {
    state.status.error = message
  },

  resetData(state) {
    state.data = {}
  },

  setTypes(state, payload) {
    payload.forEach(type => {
      let data = Vue.prototype.$formatData(type)
      if (!state.types.find(t => t.id == data.id)) {
        state.types.push(data)
      }
    })

    // state.status.getting = false
  },

  firstLoadState(state, bol) {
    state.status.firstLoad = bol
  },

  insertType(state, payload) {
    if (!state.types.find(t => t.id == payload.id)) {
      state.types.push(payload)
    }
  },

  deletingState(state, bol) {
    state.status.deleting = bol
  },

  removeType(state, payload) {
    state.types.splice(state.types.indexOf(payload), 1)
  },

  creatingProductState(state, bol) {
    state.status.creatingProduct = bol
  },

  insertProduct(state, payload) {
    if (!state.products.find(p => p.id == payload.id)) {
      state.products.push(payload)
    }
  },

  resetProduct(state) {
    state.product = {}
  },

  gettingProductsState(state, bol) {
    state.status.gettingProducts = bol
  },

  setProducts(state, payload) {
    state.products = []
    
    if (payload.size) {
      payload.forEach(doc => {
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        state.products.push(data)
      })
    }

    state.status.gettingProducts = false
  },

  setProduct(state, payload) {
    state.product = typeof payload == 'object' ? Object.assign({}, payload) : payload
  },

  setData(state, payload) {
    state.data = typeof payload == 'object' ? Object.assign({}, payload) : payload
  },

  updateTypeData(state, payload) {
    let type = state.types.find(t => t.id == payload.id)
    Vue.set(state.types, state.types.indexOf(type), payload)
  },
  
  updateProductData(state, payload) {
    let product = state.products.find(p => p.id == payload.id)
    Vue.set(state.products, state.products.indexOf(product), payload)
  },

  uploadProgress(state, val) {
    state.status.progress = val
  },

  updateDocName(state, data) {
    let id = data.id
    let name = data.name

    let type = state.types.find(t => t.id == id)
    let product = state.products.find(p => p.id == id)

    if (type) {
      let updated = Object.assign({}, type)
      updated.image = name
      Vue.set(state.types, state.types.indexOf(type), updated)
    }
    else if (product) {
      let updated = Object.assign({}, product)
      updated.image = name
      Vue.set(state.products, state.products.indexOf(product), updated)
    }
  },

  deletingImageState(state, bol) {
    state.status.deletingImage = bol
  },

  uploadingState(state, bol) {
    state.status.uploading = bol
  },

  clearImage(state) {
    state.status.image = null
  },

  removeProduct(state, product) {
    state.products.splice(state.products.indexOf(product), 1)
  },

  addImage(state, payload) {
    let image = state.images.find(i => i.id == payload.id)

    if (!image) state.images.push(payload)
    else Vue.set(state.images, state.images.indexOf(image), payload)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  updateField(state, payload) {
    Vue.set(payload.product, payload.field, payload.value)
  }
}

/*------------------------------------------------------------------------------
 * METHODS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE REQUEST TYPE
   *----------------------------------------------------------------------------*/
  async createType({ state, commit, dispatch }) {
    commit('updateStatus', { creating: true })
    if (state.status.error) commit('errorState', null)
    let hasError = false
    let user = firebase.auth().currentUser
    if (state.status.image) commit('uploadingState', true)

    let data = Object.assign({}, state.data)
    data.created = Date.now()
    data.active = true
    data.user = user.uid

    await db.collection('types')
    .add(data)
    .then((docRef) => {
      data.id = docRef.id
      data.ref = docRef
      commit('insertType', data)
      dispatch('showSuccess', 'Type created', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      hasError = true
    })
    .finally(() => {
      commit('updateStatus', { creating: false })
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * UPDATE REQUEST TYPE
   *----------------------------------------------------------------------------*/
  async updateType({ state, commit, dispatch }) {
    commit('updateStatus', { creating: true })
    
    if (state.status.error) commit('errorState', null)
    let hasError = false
    if (state.status.image) commit('uploadingState', true)

    let data = Object.assign({}, state.data)
    data.updated = Date.now()

    await data.ref
    .update(_omit(data, ['id', 'ref']))
    .then(() => {
      commit('updateTypeData', data)
      dispatch('showSuccess', 'Type updated', { root: true })
    })
    .catch(error => {
      commit('errorState', error.message)
      hasError = true
    })
    .finally(() => {
      commit('updateStatus', { creating: false })
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * UPLOAD IMAGE
   *----------------------------------------------------------------------------*/
  async uploadImage({ state, commit, dispatch }, doc) {
    var storageRef = firebase.storage().ref()
    let image = state.status.image
    
    let name = `${Date.now()}_${image.name}`

    var metadata = {
      contentType: image.type
    }

    var uploadTask  = storageRef.child(`types/${name}`).put(image, metadata)

    await uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      commit('uploadProgress', progress)
    }, error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    }, () => {
      commit('uploadProgress', 0)

      doc.ref.update({
        image: name
      })
      .then(() => {
        commit('updateDocName', {
          name,
          id: doc.id
        })

        commit('uploadingState', false)
        commit('clearImage')
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ALL
   *----------------------------------------------------------------------------*/
  async getTypes({ commit, dispatch }) {
    commit('firstLoadState', true)

    await db.collection('types')
    .orderBy('name', 'asc')
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setTypes', snapshot)
      }
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET REQUEST TYPE BY ID
   *----------------------------------------------------------------------------*/
  getType({ state, commit, dispatch }, id) {
    if (!state.types.find(t => t.id == id)) {
      db.collection('types')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          commit('insertType', data)
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE
   *----------------------------------------------------------------------------*/
  async deleteType({ commit, dispatch }, type) {
    commit('deletingState', true)

    var deleteFn = firebase.functions().httpsCallable('recursiveDelete')
    
    await deleteFn({ path: type.ref.path })
    .then(() => {
      commit('removeType', type)
      commit('deletingState', false)
      dispatch('showSuccess', 'Request type deleted', { root: true })
    })
    .catch((error) => {
      console.log(error.message)
      commit('deletingState', false)
      dispatch('showError', error.message, { root: true })
    })

  },

  /*------------------------------------------------------------------------------
   * CREATE PRODUCT
   *----------------------------------------------------------------------------*/
  createProduct({ state, commit, dispatch }, type) {
    return new Promise((resolve, reject) => {
      commit('updateStatus', { creatingProduct: true })

      let data = Object.assign({}, state.product)
      data.created = firebase.firestore.Timestamp.now()
  
      type.ref.collection('products')
      .add(data)
      .then((docRef) => {
        data.ref = docRef
        data.id = docRef.id
        commit('insertProduct', data)
        resolve(data)      
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
        reject(error.message)
      })
      .finally(() => {
        commit('updateStatus', { creatingProduct: false })
      })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE PRODUCT
   *----------------------------------------------------------------------------*/
  async updateProduct({ state, commit, dispatch }) {
    commit('creatingProductState', true)
    let hasError = false
    if (state.status.image) commit('uploadingState', true)

    let data = Object.assign({}, state.product)
    data.updated = Date.now()

    await data.ref.update(_omit(data, ['id', 'ref']))
    .then(() => {
      commit('updateProductData', data)
      commit('creatingProductState', false)
      dispatch('showSuccess', 'Product updated', { root: true })

      if (state.status.image) {
        dispatch('uploadImage', data)
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('setError', error.message)
      hasError = true
    })

    return hasError
  },

  /*------------------------------------------------------------------------------
   * DELETE PRODUCT
   *----------------------------------------------------------------------------*/
  async deleteProduct({ commit, dispatch }, product) {
    commit('deletingState', true)

    var deleteFn = firebase.functions().httpsCallable('recursiveDelete')
    
    await deleteFn({ path: product.ref.path })
    .then(() => {
      commit('deletingState', false)
      commit('removeProduct', product)
      dispatch('showSuccess', 'Product deleted', { root: true })
      if (product.image) dispatch('deleteTypeImage', product)
    })
    .catch((error) => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('deletingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET PRODUCTS
   *----------------------------------------------------------------------------*/
  getProducts({ commit, dispatch }, type) {
    commit('gettingProductsState', true)
    
    type.ref.collection('products')
    .get()
    .then(snapshot => {
      commit('setProducts', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('gettingProductsState', false)
      dispatch('showError', error.message, { root: true })
    })
  },
  
  /*------------------------------------------------------------------------------
   * GET ALL PRODUCTS
   *----------------------------------------------------------------------------*/
  getAllProducts({ commit, dispatch }) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoadAllProducts: true })
    
    db.collectionGroup('products')
    .get()
    .then(snapshot => {
      commit('setProducts', snapshot)
      commit('updateStatus', { getting: false })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET PRODUCT BY ID
   *----------------------------------------------------------------------------*/
  getProduct({ state, commit, dispatch }, data) {
    let type = data.type
    let product = data.product

    if (!state.products.find(t => t.id == type)) {
      db.collection('types').
      doc(type)
      .collection('products')
      .doc(product)
      .get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          commit('insertProduct', data)
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE TYPE/PRODUCT IMAGE
   *----------------------------------------------------------------------------*/
  async deleteTypeImage({ commit, dispatch }, doc) {
    commit('deletingImageState', true)
    
    await storageRef.child(`types/${doc.image}`)
    .delete()
    .then(() => {
      return doc.ref.update({ image: null })
      .then(() => {
        commit('deletingImageState', false)
        dispatch('showSuccess', 'Image deleted', { root: true })

        commit('updateDocName', {
          name: null,
          id: doc.id
        })

        let data = Object.assign({}, doc)
        data.image = null
        commit('setData', data)
        commit('setProduct', data)
      })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('deletingImageState', false)
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET IMAGE
   *----------------------------------------------------------------------------*/
  async getTypeImage({ commit, dispatch }, type) {
    if (type) {
      await storageRef
      .child(`request_icons/${type.icon}`)
      .getDownloadURL()
      .then(async (url) => {
        var xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
  
        xhr.onload = async () => {
          let response = xhr.response
  
          var reader = new FileReader()
          reader.readAsDataURL(response)
          reader.onloadend = () => {
            var base64data = reader.result
            

            dispatch('updateProductField', {
              silent: true,
              product: type,
              field: 'iconData',
              value: base64data,
            })
          }
        }
          
        xhr.open('GET', url)
        xhr.send()
      })
      .catch(error => {
        console.log(error.message)
        if (error.code == 'storage/object-not-found') {
          commit('addImage', {
            id: type.id,
            image: require('@/assets/placeholder.svg')
          })
        }
      })
    }
  },

  /*------------------------------------------------------------------------------
   * UPDATE PRODUCT FIELD
   *
   * @params
   *  Object
   *    product: Object
   *    field: String
   *    value: Any
   *    silent: Boolean (optional)
   *    message: String (optional)
   *----------------------------------------------------------------------------*/
  async updateProductField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.product.ref
    .update({ 
      [data.field]: data.value, 
      updated: firebase.firestore.Timestamp.now() 
    })
    .then(() => {
      commit('updateField', data)
      commit('updateStatus', { updating: false })
      if (!data.silent) dispatch('showSuccess', data.message || 'Form updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  }
  
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}