import Vue from 'vue'
import _ from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'
import userIndex from '@/algolia/init'
import generator from 'generate-password'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  hits: [],
  data: {},
  users: [],
  employees: [],
  filters: {
    role: 'all',
  },
  status: {
    error: null,
    adding: false,
    getting: false,
    deleting: false,
    inviting: false,
    display: 'list',
    firstLoad: false,
    addDialog: false,
    displayRange: 60,
    searching: false,
    inviteErrors: [],
    inviteError: null,
    lastVisible: null,
    inviteSuccess: [],
    creatingInvite: [],
    gettingEmployees: false,
    firstLoadEmployees: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getFullName: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user ? user.fullName : ''
    }
    else {
      return ''
    }
  },

  getRole: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      if (user && user.organization) return user.employeeRole
      else if (user) return user.role
      else return ''
    }
    else {
      return ''
    }
  },
  
  getEmployeeRole: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user ? user.employeeRole : ''
    }
    else {
      return ''
    }
  },
  
  getUserEmail: (state) => (id) => {
    if (state.users) {
      let user = state.users.find(u => u.userid == id)
      return user ? user.email : ''
    }
    else {
      return ''
    }
  },

  user: (state) => (id) => {
    if (state.users) {
      return state.users.find(u => u.userid == id) || {}
    }
  },
  
  employee: (state) => (id) => {
    if (state.employees) {
      return state.employees.find(u => u.userid == id) || {}
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *-----------------------------------------------------------------------------*/
const mutations = {
  setGettingUsers(state, bol) {
    state.status.getting = bol
  },

  setUsers(state, payload) {
    if (payload.size) {
      payload.forEach(item => {
        let user = item.data()
        user.id = item.id
        user.ref = item.ref
        
        if (!state.users.find(u => u.id == user.id))
          state.users.push(user)
      })

      state.status.lastVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.getting = false
    state.status.firstLoad = true
  },

  setAddDialog(state, bol) {
    state.status.addDialog = bol
  }, 

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertUser(state, payload) {
    if (!state.users.find(u => u.id == payload.id)) {
      let data
      
      try {
        data = payload.data()
        data.id = payload.id
        data.ref = payload.ref
      }
      catch {
        data = payload
      }
      
      state.users.push(data)
    }
  },

  setData(state, payload) {
    state.data = payload
  },

  setDeleting(state, bol) {
    state.status.deleting = bol
  },

  removeUser(state, user) {
    state.users.splice(state.users.indexOf(user), 1)
  },

  setDisplayRange(state, num) {
    state.status.displayRange = num
  },

  invitingState(state, bol) {
    state.status.inviting = bol
  },

  inviteErrorState(state, message) {
    state.status.inviteError = message
  },

  clearHits(state) {
    state.hits = []
  },

  insertHit(state, payload) {
    let hit = Vue.prototype.$formatData(payload)
    state.hits.push(hit)
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setEmployees(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let employee = doc.data()
        employee.id = doc.id
        employee.ref = doc.ref
        state.employees.push(employee)
      })
    }

    state.status.gettingEmployees = false
  },

  addEmployee(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref

    if (!state.employees.find(e => e.id == payload.id)) 
      state.employees.push(data)
  },

  setHits(state, payload) {
    state.hits = []

    payload.forEach(doc => {
      if (doc.exists) {
        let user = doc.data()
        user.id = doc.id
        user.ref = doc.ref
        state.hits.push(user)
      }
    })

    state.status.searching = false
  },

  addInvite(state, payload) {
    if (!state.status.creatingInvite.find(i => i.email == payload.email))
      state.status.creatingInvite.push(payload)
  },

  addInviteError(state, payload) {
    if (!state.status.inviteErrors.find(e => e.email == payload.email)) 
      state.status.inviteErrors.push(payload)
  },

  removeCreatingInvite(state, email) {
    let invite = state.status.creatingInvite.find(i => i.email == email)
    if (invite) state.status.creatingInvite.splice(state.status.creatingInvite.indexOf(invite), 1)
  },

  addInviteSuccess(state, email) {
    if (!state.status.inviteSuccess.find(e => e == email))
      state.status.inviteSuccess.push(email)
  },

  resetInvites(state) {
    state.status.inviteErrors = []
  },

  clearUsers(state) {
    state.users = []
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {

  /*------------------------------------------------------------------------------
   * GET USERS
   *----------------------------------------------------------------------------*/
  getUsers({ state, commit, dispatch }) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    
    let query = db.collection('users')
    .orderBy('createdAt', 'desc')

    // FILTER BY ROLE
    if (!['all', 'employee'].includes(state.filters.role)) {
      query = query.where('role', 'in', [state.filters.role])
    }
    else if (state.filters.role === 'employee') {
      query = query.where('organization', '==', true)
    }

    // PAGINATION
    if (state.status.lastVisible) {
      query = query.startAfter(state.status.lastVisible)
    }
    
    query.limit(20)
    .get()
    .then(snapshot => {
      if (snapshot.size)  {
        commit('setUsers', snapshot)
      }
      else {
        commit('updateStatus', { getting: false })
      }
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ADD USER
   *----------------------------------------------------------------------------*/
  addUser({ commit, state, dispatch }) {
    commit('setAddingState', true)

    let password = generator.generate({
      length: 10,
      numbers: true
    })

    if (!state.data.id) {
      db.collection('users')
      .where('email', '==', state.data.email)
      .limit(1)
      .get()
      .then(snapshot => {
        if (!snapshot.size) {
          db.collection('users').add({
            userid: null,
            avatar: null,
            toCreate: true,
            organization: false,
            updatedAt: Date.now(),
            role: state.data.role,
            createdAt: Date.now(),
            tempPassword: password,
            email: state.data.email,
            fullName: state.data.fullName,
          })
          .then(() => {
            commit('setAddingState', false)
            commit('setAddDialog', false)
            dispatch('showSuccess', 'User successfully added.', { root: true })
          })
          .catch(error => {
            commit('setError', error.message)
            commit('setAddingState', false)
          })
        }
        else {
          commit('setError', 'Email address was already taken from an existing user.')
          commit('setAddingState', false)
        }

      })
      .catch(error => {
        commit('setError', error.message)
        commit('setAddingState', false)
      })
    }
    else {
      let user = state.users.find(u => u.id === state.data.id)

      if (user) {
        var prevUserData = Object.assign({}, user)
      }

      state.data.ref
      .update(_.omit(state.data, ['ref', 'id']))
      .then(() => {
        
        // SEND EMAIL IF USER ROLE WAS UPDATED TO CLIENT
        if (prevUserData.role === 'user' && state.data.role === 'client') {
          dispatch('email/sendMail', {
            to: state.data.email,
            subject: 'Lightning Builder Account Role Updated',
            message: `
              Hi ${state.data.fullName},<br/>
              <br/>
              Thanks for signing up to Lightning Builder!<br/>
              Your account has now been upgraded to client privileges. Log in now to start accessing all the features including creating customer requests. Remember you can live chat with us if you have any questions!
            `
          }, { root: true })
        }

        dispatch('showSuccess', 'User data was successfully updated.', { root: true })
        commit('setAddingState', false)
        commit('setAddDialog', false)
        commit('setData', {})
      })
      .catch(error => {
        commit('setError', error.message)
        commit('setAddingState', false)
      })
    }


  },

  /*------------------------------------------------------------------------------
   * GET USER BY ID
   *
   * @params
   *  id: String (auth uid)
   *----------------------------------------------------------------------------*/
  async getUser({ state, dispatch, commit }, id) {
    if (!state.users.find(u => u.userid == id)) {
      await db.collection('users')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) commit('insertUser', doc)
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET USERDATA BY UID
   *----------------------------------------------------------------------------*/
  async getUserUid({ state, dispatch, commit }, id) {
    if (!state.users.find(u => u.userid == id)) {
      await db.collection('users')
      .where('userid', '==', id)
      .limit(1).get()
      .then(snapshot => {
        if (snapshot.size) {
          let doc = snapshot.docs[0]
          commit('insertUser', doc)
          
          if (doc.data().requestPackage) {
            dispatch('request/getPackage', doc.data().requestPackage, { root: true })
          }
          
          if (doc.data().requestPackages && doc.data().requestPackages.length) {
            doc.data().requestPackages.forEach(pkg => {
              dispatch('request/getPackage', pkg, { root: true })
            })
          }
        }
      })
      .catch(error => {
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * DELETE
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, user) {
    commit('setDeleting', true)

    const deleteUser = firebase.functions().httpsCallable('user-deleteAuthUser')
    
    await deleteUser({ userid: user.userid })
    .then(() => {
      commit('setDeleting', false)
      commit('removeUser', user)
      dispatch('showSuccess', 'User successfully deleted.', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * INVITE USER
   *----------------------------------------------------------------------------*/
  async inviteUser({ state, commit, dispatch }, data) {
    commit('invitingState', true)
    if (state.status.inviteError) commit('inviteErrorState', null)
    let hasError = false
    
    await db.collection('users')
      .where('email', '==', data.email)
      .limit(1).get()
      .then(async (snapshot) => {
        if (!snapshot.size) {
          commit('inviteErrorState', 'This user already exists.')
          commit('invitingState', false)
          hasError = true
        }
        else {
          let message = {
            to: data.email,
            template: {
              name: "notification",
              data: {
                subject: 'Create An Account',
                message: `
                  Hi,<br><br>
                  You are invited to create an account on Lightning Builder.<br>
                  <a href="${window.location.origin}/signup?email=${data.email}" target="_blank">Click Here To Signup</a>
                  <br><br>
                  All The Best,<br>
                  Lightning Builder Team
                `,
              },
            },
          }

          await db.collection('mail')
          .add(message)
          .then(() => {
            commit('invitingState', false)
            dispatch('showSuccess', 'Invitation sent', { root: true })
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        commit('inviteErrorState', error.message)
        hasError = true
      })
    
    return hasError
  },

  /*------------------------------------------------------------------------------
   * SEARCH USER
   *----------------------------------------------------------------------------*/
  searchUser({ commit, dispatch }, keyword) {
    commit('updateStatus', { searching: true })
    commit('clearHits')

    userIndex.search(keyword)
    .then(({ hits }) => {
      if (hits.length) {
        let promises = []

        for (var hit of hits) {
          promises.push(db.collection('users').doc(hit.objectID).get())
        }

        Promise.all(promises)
        .then(docs => {
          commit('setHits', docs)
        })
      }
      else {
        commit('updateStatus', { searching: false })
        dispatch('showError', 'No user found', { root: true })
      }
    })
    .catch(error => {
      console.log(error)
      commit('updateStatus', { searching: false })
    })
  },

  /*------------------------------------------------------------------------------
   * GET EMPLOYEES
   *----------------------------------------------------------------------------*/
  getEmployees({ commit }) {
    commit('updateStatus', { gettingEmployees: true })
    commit('updateStatus', { firstLoadEmployees: true })
    
    db.collection('users')
    .where('organization', '==', true)
    .get()
    .then(snapshot => {
      commit('setEmployees', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { gettingEmployees: false })
    })
  },

  /*------------------------------------------------------------------------------
   * GET EMPLOYEE
   *----------------------------------------------------------------------------*/
  getEmployee({ commit }, id) {
    db.collection('users')
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        commit('addEmployee', doc)
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * CREATE INVITE
   *----------------------------------------------------------------------------*/
  createInvite({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('addInvite', data)
      let user = firebase.auth().currentUser
      data.created = firebase.firestore.Timestamp.now()
      data.invitedBy = user.uid

      // IF USER DOESN'T HAVE ROLE ASSIGNED
      if (!data.role) {
        commit('addInviteError', { email: data.email, error: 'No role assigned' })
        commit('removeCreatingInvite', data.email)
        reject(`No role assigned for ${data.email}`)
      }
      // IF USER HAVE ROLE ASSIGNED
      // BUT NO PACKAGE SELECTED
      else if (data.role == 'client' && !data.package) {
        commit('addInviteError', { email: data.email, error: 'No package assigned' })
        commit('removeCreatingInvite', data.email)
        reject(`No package assigned for ${data.email}`)
      }
  
      db.collection('users')
      .where('email', '==', data.email)
      .get()
      .then((snapshot) => {
        if (!snapshot.size) {
          data.created = firebase.firestore.Timestamp.now()

          db.collection('user_invites')
          .where('email', '==', data.email)
          .get()
          .then((snapshot) => {
            let docRef = snapshot.size ? db.collection('user_invites').doc(snapshot.docs[0].id) : db.collection('user_invites').doc()

            docRef.set(data)
            .then(() => {
              commit('addInviteSuccess', data.email)
              commit('removeCreatingInvite', data.email)
              var message = ''


              if (data.role == 'client') {
                message = `
                  Hey There!, <br>
                  <br>Welcome to the launch of the new Customer Service Portal in our Lightning Builder App. This will be the new way for you and your team to log service requests. 
                  <br><br>As a valued customer of Lightning Sites or Dance Sites we invite your to activate your Lightning  Builder account and set your password on the link below. 
                  <br/>
                  ${window.origin}/signup/?i=${docRef.id}
                  <br><br>Once activated, you may login and commence lodging Customer Support requests and you can even Live Chat with the Customer Support Champs within the app also from Monday 22 August. 
                  <br><br>Don't forget to save your account login details for future use!
                  <br><br>
                  Regards,<br>
                  Lightning Sites & Dance Sites Customer Service Portal
                `
              }
              else {
                message = `
                  Hi, <br>
                  <br>
                  You have been added as a User to our Lightning Builder app. Click the link below to set your password...<br/>
                  <br/>
                  ${window.origin}/signup/?i=${docRef.id}<br>
                  <br/>
                  If you have any questions please live chat with us inside the app at builder.lightningsites.com.
                `
              }
              
              let emailData = {
                to: data.email,
                subject: 'You Are Invited',
                message
              }
              
              dispatch('email/sendMail', emailData, { root: true })
              resolve('Invite created')
            })
          })
          .catch(error => {
            commit('addInviteError', { email: data.email, error: error.message })
            commit('removeCreatingInvite', data.email)
            reject('User already exists')
          })
        }
        else {
          commit('addInviteError', { email: data.email, error: 'User already exists' })
          commit('removeCreatingInvite', data.email)
          reject('User already exists')
        }
      })
      .catch(error => {
        commit('addInviteError', { email: data.email, error: error.message })
        commit('removeCreatingInvite', data.email)
        reject(error.message)
      })
    })

    // db.collection('invited_users')
    // .add(data)
  }


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
