import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        dark: '#4d4d4d',
        accent: '#FBBB00',
        primary: '#283F97',
        success: '#8bdf55',
        secondary: '#132D3D',
        anchor: 'rgba(40,63,151,0.5)',
      },
    },
    options: { customProperties: true },
  },
});
