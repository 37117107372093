import { render, staticRenderFns } from "./BlockImage.vue?vue&type=template&id=cf4605f8&"
import script from "./BlockImage.vue?vue&type=script&lang=js&"
export * from "./BlockImage.vue?vue&type=script&lang=js&"
import style0 from "./BlockImage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VBtn,VCard,VFadeTransition,VHover,VIcon,VOverlay})
