import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import firebase from 'firebase'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Homev2.vue'),
    meta: { guestOnly: true, resetPadding: true }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/common/PageNotFound.vue'),
  },
  {
    path: '/admin',
    component: () => import('../views/admin/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Admin',
        component: () => import('../views/users/Users.vue'),
        meta: {  requiresAuth: true, access: ['admin'], allow: 'manageUsers' }
      },
      {
        path: 'panels',
        name: 'Panels',
        component: () => import('../views/admin/Panels.vue'),
        meta: {  requiresAuth: true, access: ['admin'], allow: 'manageBlocks' }
      },
      {
        path: 'page-templates',
        name: 'PageTemplates',
        component: () => import('../views/PageTemplates/PageTemplates.vue'),
        meta: {  requiresAuth: true, access: ['admin'], allow: 'managePageTemplates' }
      },
      {
        path: 'project-templates',
        name: 'ProjectTemplates',
        component: () => import('../views/ProjectTemplates/ProjectTemplates.vue'),
        meta: {  requiresAuth: true, access: ['admin'], allow: 'manageProjectTemplates' }
      },
      {
        path: 'general-settings',
        name: 'GeneralSettings',
        component: () => import('../views/admin/GeneralSettings.vue'),
        meta: {  requiresAuth: true, access: ['admin'] }
      },
    ]
  },
  {
    path: '/projects',
    component: () => import('../views/common/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Projects',
        component: () => import('../views/common/Projects.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'all',
        name: 'AllProjects',
        component: () => import('../views/admin/AllProjects.vue'),
        meta: {  requiresAuth: true, access: ['admin'], allow: 'manageProjects' }
      },
      {
        path: 'folder/:id',
        name: 'Folder',
        component: () => import('../views/common/Folder.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: ':id',
        name: 'Project',
        component: () => import('../views/project/Project.vue'),
        meta: { requiresAuth: false }
      },
    ]
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: () => import('../views/account/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/auth/Signup.vue'),
    meta: { guestOnly: true, resetPadding: true, hideHeader: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/auth/ConfirmResetPassword.vue'),
    meta: { guestOnly: true, resetPadding: true, hideHeader: true }
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: () => import('../views/common/PageNotFound.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/common/Test.vue'),
  },

  /*------------------------------------------------------------------------------
   * REQUESTS
   *----------------------------------------------------------------------------*/
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/request/Requests.vue'),
    meta: {  requiresAuth: true},
    children: [
      {
        path: '/',
        name: 'OpenRequests',
        component: () => import('../views/request/OpenRequests.vue'),
        meta: { requiresAuth: true}
      },
      {
        path: 'completed',
        name: 'CompletedRequests',
        component: () => import('../views/request/OpenRequests.vue'),
        meta: { requiresAuth: true, showDrawer: true}
      },
      {
        path: 'pending-review',
        name: 'PendingReviewRequests',
        component: () => import('../views/request/OpenRequests.vue'),
        meta: { requiresAuth: true, showDrawer: true}
      },
      {
        path: 'types',
        name: 'RequestTypes',
        component: () => import('../views/request/Types.vue'),
        meta: { requiresAuth: true, access: ['admin'], allow: 'manageRequests' }
      },
      {
        path: 'types/:id',
        name: 'RequestType',
        component: () => import('../views/request/Type.vue'),
        meta: { requiresAuth: true, access: ['admin'], allow: 'manageRequests' }
      },
      {
        path: 'types/:id/builder/:product',
        name: 'ProductFormBuilder',
        component: () => import('../views/request/FormBuilder.vue'),
        meta: { requiresAuth: true, access: ['admin'], allow: 'manageRequests' }
      },
      {
        path: 'packages',
        name: 'RequestPackages',
        component: () => import('../views/request/RequestPackages.vue'),
        meta: { requiresAuth: true, showDrawer: true, access: ['admin'], allow: 'manageRequests' }
      },
      {
        path: 'canned-responses',
        name: 'RequestCannedResponses',
        component: () => import('../views/canned_responses/CannedResponses.vue'),
        meta: { requiresAuth: true, showDrawer: true, access: ['admin'], allow: 'manageRequests' }
      },
    ],
  },
  {
    path: '/requests/create-request',
    name: 'CreateRequest',
    component: () => import('../views/request/CreateRequestParent.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'SelectRequestType',
        component: () => import('../views/request/CreateRequest.vue'),
        meta: { showDrawer: true }
      },
      {
        path: ':id',
        name: 'SelectRequestProduct',
        component: () => import('../views/request/SelectRequestProduct.vue'),
        meta: { showDrawer: true }
      },
      {
        path: ':id/:product',
        name: 'RequestForm',
        component: () => import('../views/request/RequestForm.vue'),
        meta: { showDrawer: true }
      },
    ]
  },
  {
    path: '/requests/:id',
    name: 'Request',
    component: () => import('../views/request/Request.vue'),
    meta: { requiresAuth: true}
  },

  /*------------------------------------------------------------------------------
   * ORDERS
   *----------------------------------------------------------------------------*/
  {
    path: '/order',
    name: 'OrderPage',
    component: () => import('../views/orders/Order.vue'),
    meta: { hideHeader: true, resetPadding: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/orders/Main.vue'),
    meta: {  requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'InProgressOrders',
        component: () => import('../views/orders/InProgress.vue'),
        meta: { showDrawer: true },
      },
      {
        path: 'completed',
        name: 'CompletedOrders',
        component: () => import('../views/orders/InProgress.vue'),
        meta: { showDrawer: true },
      },
      {
        path: 'forms',
        name: 'OrderForms',
        component: () => import('../views/orders/Forms.vue'),
        meta: { requiresAuth: true, access: ['admin'], allow: 'manageOrders' }
      },
      {
        path: 'forms/create-form/:id',
        name: 'CreteOrderForm',
        component: () => import('../views/orders/components/FormBuilder.vue'),
        meta: { requiresAuth: true, access: ['admin'], allow: 'manageOrders' }
      },
      {
        path: 'order-terms',
        name: 'OrderTerms',
        component: () => import('../views/orders/OrderTerms.vue'),
        meta: { showDrawer: true }
      },
      {
        path: 'canned-responses',
        name: 'OrderCannedResponses',
        component: () => import('../views/canned_responses/CannedResponses.vue'),
        meta: { showDrawer: true }
      },
    ]
  },
  {
    path: '/create-order',
    name: 'CreateOrder',
    component: () => import('../views/orders/CreateOrder.vue'),
    meta: { showDrawer: true, requiresAuth: true }
  },
  {
    path: '/orders/:id',
    name: 'OrderDetails',
    component: () => import('../views/orders/OrderDetails.vue'),
    meta: {  requiresAuth: true, showDrawer: true},
  },
  {
    path: '/company-profiles',
    component: () => import('../views/brand_profiles/Main.vue'),
    meta: { requiresAuth: true},
    children: [
      {
        path: '/',
        name: 'BrandProfiles',
        component: () => import('../views/brand_profiles/BrandProfiles.vue'),
      },
      {
        path: ':id',
        name: 'EditBrandProfile',
        component: () => import('../views/brand_profiles/EditBrandProfile.vue'),
      },
    ]
  },
  {
    path: '/company-profile/:id',
    name: 'BrandProfile',
    component: () => import('../views/brand_profiles/BrandProfile.vue'),
    meta: {  showDrawer: true},
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/stats'),
    meta: {  requiresAuth: true, showDrawer: true},
    children: [
      {
        path: '/',
        name: 'UsersStats',
        component: () => import('../views/stats/UsersStats.vue'),
        meta: {  requiresAuth: true, showDrawer: true},
      },
      {
        path: 'requests',
        name: 'RequestStats',
        component: () => import('../views/stats/RequestStats.vue'),
        meta: {  requiresAuth: true, showDrawer: true},
      },
    ]
  },
  {
    path: '/book-schedule',
    name: 'BookSchedule',
    component: () => import('../views/schedule/BookSchedule.vue'),
    meta: {  showDrawer: true, requiresAuth: true},
  },
  {
    path: '/convert',
    name: 'ConvertSvg',
    component: () => import('../views/panels/ConvertSvg.vue'),
    meta: {  showDrawer: true, requiresAuth: true},
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (firebase.auth().currentUser && to.matched.some(record => record.meta.access)) {

      to.matched.some(record => {
        var access = record.meta.access

        if (store.state.user) {
          var checkUser = setInterval(function () {
            if (store.state.user.user && access) {
              let role = store.state.user.user.role
              access.includes(role) || (store.state.user.user.organization && store.state.user.user[record.meta.allow]) ? next() : next({ name: 'Home' })
              clearInterval(checkUser)
            }
          }, 100)
        }
      })

    }
    else {
      !firebase.auth().currentUser ? next({ name: 'Home' }) : next()
    }
  }
  else if (to.matched.some(record => record.meta.guestOnly)) {
    if (firebase.auth().currentUser) {
      if (store.state.user) {
        var checkUser = setInterval(function () {
          if (store.state.user.user) {
            let role = store.state.user.user.role
            if (role == 'admin') next({ name: 'Admin' }) 
            else if (role == 'client') next({ name: 'OpenRequests' })
            else next({ name: 'Projects' })
            clearInterval(checkUser)
          }
        }, 100)
      }
    }
    else {
      next()  
    }
  } else {
    next()
  }
})

export default router
