import Vue from 'vue'
import { pick } from 'lodash'
import router from '@/router'
import firebase from 'firebase'
import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialState = () => {
  return {
    data: {},
    newOrders: [],
    completedOrders: [],
    status: {
      data: {},
      search: {},
      getting: false,
      creating: false,
      updating: false,
      deleting: false,
      firstLoad: false,
      lastVisible: null,
      creatingMessage: false,
      gettingNewOrders: false,
      employeeView: 'assignee',
      completedFirstLoad: false,
      lastCompletedVisible: null,
    }
  }
}

const searchState = () => {
  return {
    search: {
      value: null,
      search: null,
      assignee: null,
      orderType: null,
    }
  }
}

const state = {
  ...initialState(),
  ...searchState()
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  order: (state) => (id) => {
    if (state.newOrders) {
      let order = state.newOrders.find(o => o.id == id)
      if (!order) order = state.completedOrders.find(o => o.id == id)
      return order || {}
    }
  },

  haveAccess: (state, getters, rootState) => (order) => {
    if (rootState.user.user && order) {
      let user = rootState.user.user
      return user.role == 'admin' || user.userid == order.user || user.organization
    }
    else {
      return false
    }
  },
  
  canManage: (state, getters, rootState) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return user.role == 'admin' || (user.organization && user.manageOrder)
    }
    else return false
  },
  
  canChangeStatus: (state, getters, rootState) => (order) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return user.role == 'admin' || (user.organization && user.manageOrder) || order.assignee == user.userid
    }
    else return false
  },

  isOwner: (state, getters, rootState) => (order) => {
    if (rootState.user.user) {
      let user = rootState.user.user
      return user.userid == order.user
    }
    else return false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  setNewOrders(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        if (!state.newOrders.find(o => o.id == doc.id)) {
          let order = doc.data()
          order.id = doc.id
          order.ref = doc.ref
          state.newOrders.push(order)
        }
      })

      state.status.lastVisible = payload.docs[payload.docs.length - 1]
    }
    
    state.status.gettingNewOrders = false
  },
  
  setCompletedOrders(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        if (!state.completedOrders.find(o => o.id == doc.id)) {
          let order = doc.data()
          order.id = doc.id
          order.ref = doc.ref
          state.completedOrders.push(order)
        }
      })

      state.status.lastCompletedVisible = payload.docs[payload.docs.length - 1]
    }
    
    state.status.gettingNewOrders = false
  },

  addOrder(state, payload) {
    if (payload.status !==  'completed') {
      let order = state.newOrders.find(o => o.id == payload.id)
      if (!order) state.newOrders.push(payload)
      else Vue.set(state.newOrders, state.newOrders.indexOf(order), payload)
    }
    else {
      let order = state.completedOrders.find(o => o.id == payload.id)
      if (!order) state.completedOrders.push(payload)
      else Vue.set(state.completedOrders, state.completedOrders.indexOf(order), payload)
    }
  },

  prependOrder(state, payload) {
    let order = state.newOrders.find(o => o.id == payload.id)
    if (!order) state.newOrders.unshift(payload)
  },

  setOrderField(state, payload) {
    Vue.set(payload.order, payload.field, payload.value)
  },

  removeOrder(state, payload) {
    let order = state.newOrders.find(o => o.id == payload.id)
    let completeOrder = state.completedOrders.find(o => o.id == payload.id)
    if (order) state.newOrders.splice(state.newOrders.indexOf(order), 1)
    if (completeOrder) state.completedOrders.splice(state.completedOrders.indexOf(completeOrder), 1)
  },

  resetState(state) {
    Object.assign(state, {
      ...initialState(),
      ...searchState()
    })
  },

  clearOrders(state) {
    state.newOrders = []
    state.completedOrders = []
  },

  resetOrders(state) {
    Object.assign(state, initialState())
  },

  updateSearch(state, payload) {
    state.search[Object.keys(payload)[0]] = Object.values(payload)[0]
  },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * CREATE ORDER
   *----------------------------------------------------------------------------*/
  async createOrder({ state, commit, dispatch, rootState }, entryData) {
    let orderNumber = 1
    
    await db.collection('orders')
    .orderBy('orderNumber', 'desc')
    .limit(1).get()
    .then((snapshot) => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        orderNumber = doc.data().orderNumber
        orderNumber++
      }
    })

    commit('updateStatus', { creating: true })
    let fields = entryData.fields
    let user = rootState.user.user
    let form = entryData.form
    let entries = []
    let data = {
      orderNumber,
      form: form.id,
      assignee: null,
      user: user.userid,
      status: 'pending_payment',
      productLabel: form.productName,
      priority: form.priority || 'low',
      created: firebase.firestore.Timestamp.now(),
    }

    let orderData = Object.assign(data, state.data)

    fields.forEach(field => {
      let entry = pick(field, ['order', 'label', 'value'])
      entries.push(entry)
    })

    orderData.entries = entries
    
    await db.collection('orders')
    .add(orderData)
    .then(async (docRef) => {
      orderData.ref = docRef
      orderData.id = docRef.id
      dispatch('products/payOnce', orderData, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { creating: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * FINALIZE ORDER
   *----------------------------------------------------------------------------*/
  async finalizeOrder({ dispatch, rootState, rootGetters }, data) {
    let form = data.form
    let order = data.order
    let user = rootState.user.user

    order.ref.update({ 
      status: 'collecting_content',
      assignee: form.assignee,
      followers: form.followers || null
    })

    if (form.webhook) {
      let hookData = {
        text: `*${user.fullName}* placed a new _${form.name}_ order. View order details ${window.origin}/orders/${order.id}`,
        url: form.webhook
      }

      await dispatch('webhook/googleChat', hookData, { root: true })
    }

    let message1 = {
      html: false,
      note: false,
      type: 'status_change',
      message: 'Order was placed',
      created: firebase.firestore.Timestamp.now(),
    }

    // CREATE DEFAULT MESSAGE
    await Promise.resolve(
      dispatch('createMessage', {
        ref: order.ref,
        data: message1,
        sender: user.userid,
      })
    ).then(() => {
      if (form.autoResponse) {
        let message2 = {
          html: true,
          message: form.autoResponse.message,
          created: firebase.firestore.Timestamp.now(),
          note: false,
        }

        dispatch('createMessage', {
          ref: order.ref,
          data: message2,
          sender: form.autoResponse.sender,
        })
      }
    })

    // SEND NOTIFICATION TO ASSIGNEE
    await Promise.resolve(dispatch('users/getUserUid', form.assignee, { root: true }))
    .then(() => {
      let mailData = {
        to: rootGetters['users/user'](form.assignee).email,
        subject: `New ${form.name} order`,
        message: `
          Hi ${rootGetters['users/user'](form.assignee).fullName},<br/><br/>
          An order has been assigned to you.<br/>
          Click <a href="${window.origin}/orders/${order.id}">here</a> for more details.
        `
      }

      dispatch('email/sendMail', mailData, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET NEW ORDERS
   *----------------------------------------------------------------------------*/
  async getNewOrders({ state, commit, dispatch, rootState }, completed = false) {
    !completed ?  commit('updateStatus', { firstLoad: true }) : commit('updateStatus', { completedFirstLoad: true })
    commit('updateStatus', { gettingNewOrders: true })
    let user = rootState.user.user
    
    if (user) {
      let task = db.collection('orders')
      let statuses = ['collecting_content', 'in_progress', 'checking_quality', 'reviewing_with_client', 'paused', 'escalated']
      
      // IF SEARCH TYPE IS SELECTED
      if (state.search.search) {
        if (state.search.search == 'orderType') {
          console.log(state.search.value)
          task = task.where('form', 'in', state.search.value)
                     .where('status', completed ? '==' : '!=', 'completed')
        }
        else if (state.search.search == 'follower') {
          task = task.where('followers', 'array-contains', state.search.value)
        }
        else {
          task = task.where(`${state.search.search}`, '==', state.search.value)
        }
      }
      // IF USER IS AN EMPLOYEE BUT NOT ADMIN
      else if (user.organization && user.role !== 'admin' && !user.manageOrder) {
        if (state.status.employeeView == 'assignee')
          task = task.where('assignee', '==', user.userid)
        else if (state.status.employeeView == 'follower')
          task = task.where('followers', 'array-contains', user.userid)
      }
      // IF USER IS ONLY ADMIN
      else if (user.role !== 'admin' && !user.manageOrder) {
        task = task.where('user', '==', user.userid)
        statuses.push('pending_payment')
      }
  
      if (state.search.search !== 'orderType') 
        task = task.where('status', 'in', completed ? ['completed'] : statuses)
      if (state.search.search !== 'orderNumber' && (state.search.search !== 'orderType' && !completed)) 
        task = task.orderBy('orderNumber', 'desc')
  
      if (!completed && state.status.lastVisible) task = task.startAfter(state.status.lastVisible)
      else if (completed && state.status.lastCompletedVisible) task = task.startAfter(state.status.lastCompletedVisible)
      
      await task.limit(15)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          if (!completed) commit('setNewOrders', snapshot)
          else commit('setCompletedOrders', snapshot)
    
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let order = doc.data()
              order.id = doc.id
              order.ref = doc.ref
              dispatch('getAssociates', order)
              dispatch('products/getPrice', order.price, { root: true })
            })
          }
        }
        else {
          dispatch('showError', 'No more orders found.', { root: true })
          commit('updateStatus', { gettingNewOrders: false })
        }
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET ORDER
   *----------------------------------------------------------------------------*/
  getOrder({ state, commit, dispatch }, id) {
    commit('updateStatus', { getting: true })
    
    if (!state.newOrders.find(o => o.id === id) && !state.completedOrders.find(o => o.id === id)) {
      db.collection('orders')
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let order = doc.data()
          order.id = doc.id
          order.ref = doc.ref
          commit('addOrder', order)
          dispatch('getAssociates', order)
          dispatch('products/getPrice', order.price, { root: true })
          
          if (order.followers && order.followers.length) {
            order.followers.forEach(user => {
              dispatch('users/getUserUid', user, { root: true })
            })
          }
        }
        
        commit('updateStatus', { getting: false })
      })
    }
    else {
      commit('updateStatus', { getting: false })
    }
  },

  /*------------------------------------------------------------------------------
   * CHECK NEW ORDER
   *----------------------------------------------------------------------------*/
  checkNewOrder({ state, commit, dispatch }) {
    db.collection('orders')
    .where('status', '==', 'in_progress')
    .onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        let order = doc.data()
        order.id = doc.id
        order.ref = doc.ref
        
        if (!state.newOrders.find(o => o.id == order.id)) {
          commit('prependOrder', order)
          dispatch('getAssociates', order)
        }
      })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE ORDER FIELD
   * @params
   *  Object
   *    order: Object
   *    field: String
   *    value: Any
   *    message: String (optional) 
   *    silent: Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateOrderField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    let updated = firebase.firestore.Timestamp.now()
    
    await data.order.ref
    .update({ [data.field]: data.value, updated })
    .then(() => {
      commit('updateStatus', { updating: false })
      commit('setOrderField', {
        order: data.order,
        field: data.field,
        value: data.value
      })
      
      commit('setOrderField', {
        order: data.order,
        field: 'updated',
        value: updated
      })

      if (!data.silent) dispatch('showSuccess', data.message || 'Order updated.', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET ASSOCIATES
   *----------------------------------------------------------------------------*/
  getAssociates({ dispatch }, order) {
    dispatch('users/getUserUid', order.user, { root: true })
    dispatch('orderform/getForm', order.form, { root: true })
    dispatch('users/getUserUid', order.assignee, { root: true })
    if (order.brandProfile) dispatch('brand_profile/getProfile', order.brandProfile, { root: true })
    if (order.project) dispatch('projects/getProject', order.project, { root: true })
  },

  /*------------------------------------------------------------------------------
   * CREATE MESSAGE
   * @params
   * - Object reference
   * - Object data
   * - String sender (optional)
   *----------------------------------------------------------------------------*/
  async createMessage({ commit, dispatch }, data) {
    commit('updateStatus', { creatingMessage: true })
    let user = firebase.auth().currentUser
    
    let docRef = data.ref
    let message = data.data
    message.sender = data.sender || user.uid
    
    await docRef
    .collection('messages')
    .add(message)
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { creatingMessage: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE ORDER
   *
   * @PARAMS
   * - Object order
   *----------------------------------------------------------------------------*/
  async deleteOrder({ commit, dispatch }, order) {
    commit('updateStatus', { deleting: true })
    let user = firebase.auth().currentUser
    const deleteTask = firebase.functions().httpsCallable('recursiveDelete')
    
    await deleteTask({ path: order.ref.path })
    .then(() => {
      commit('updateStatus', { deleting: false })
      commit('removeOrder', order)
      dispatch('showSuccess', 'Order deleted', { root: true })

      dispatch('log_histories/createHistory', {
        user: user.uid,
        document: order.orderNumber,
        action: 'deleted',
        collection: 'orders'
      }, { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SEND ASSIGNEE NOTIFICATION
   *
   * @params
   *  Object
   *    order: String (id)
   *    user: String (auth uid)
   *----------------------------------------------------------------------------*/
  sendAssigneeNotification({ dispatch, rootGetters }, data) {
    let order = data.order
    let user = rootGetters['users/user'](data.user)

    dispatch('email/sendMail', {
      to: user.email,
      subject: 'An order has been assigned to you',
      message: `
        Hi ${user.fullName},<br/><br/>
        An order has been assigned to you.<br/>
        Click <a href="${window.origin}${router.resolve({ name: 'OrderDetails', params: { id: order } }).href}">here</a> for more details.
      `
    }, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}