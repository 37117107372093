import db from '@/firebase/init'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  status: {
    sending: false,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 * @params
 *  Object
 *    to: String
 *    subject: String
 *    message: String (accepts html tags)
 *----------------------------------------------------------------------------*/
const actions = {
  async sendMail({ commit }, data) {
    commit('updateStatus', { sending: true })

    let message = {
      to: data.to,
      template: {
        name: "notification",
        data: {
          subject: data.subject,
          message: data.message,
        },
      },
    }

    await db.collection('mail')
    .add(message)
    .then(() => {
      commit('updateStatus', { sending: false })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { sending: false })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
