<template>
  <v-dialog
    :value="status.showDialog"
    :max-width="$dialog.content"
    persistent
    no-click-animation
  >
    <close-btn @click="close()" class="mt-2" overflow />
    
    <v-card>
       <v-card-text class="pa-2 pb-0">
        <v-alert v-if="status.error" transition="fade" type="error" border="left" class="mb-0" text>
          {{ status.error }}
        </v-alert>
      </v-card-text>

      <!-- SIGNUP -->
      <SignupForm ref="signup" v-if="status.action == 'signup'" @login="$store.commit('user/setActionType', 'login')"/>

      <!-- lOGIN -->
      <LoginForm ref="login" v-if="status.action == 'login'" @signup="$store.commit('user/setActionType', 'signup')"  @reset="$store.commit('user/setActionType', 'reset')"/>

      <!-- RESET PASSWORD -->
      <ResetPassword ref="reset" v-if="status.action == 'reset'" @login="$store.commit('user/setActionType', 'login')"/>

    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex'
import LoginForm from '@/components/LoginForm'
import SignupForm from '@/components/SignupForm'
import ResetPassword from '@/components/ResetPassword'

export default {
  name: 'Login',

  components: {
    LoginForm,
    SignupForm,
    ResetPassword
  },

  computed: {
    ...mapState({
      status: state => state.user.status,
    })
  },

  methods: {
    close() {
      this.$store.commit('user/setShowDialog', false)
      this.$store.commit('user/clearData')
      if (this.$refs.signup) this.$refs.signup.$refs.signupForm.resetValidation()
      if (this.$refs.login) this.$refs.login.$refs.loginForm.resetValidation()
      if (this.$refs.reset) this.$refs.reset.$refs.resetForm.resetValidation()
    },
  }
}

</script>
