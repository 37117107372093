import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import page from './modules/page'
import copy from './modules/copy'
import users from './modules/users'
import notes from './modules/notes'
import types from './modules/types'
import email from './modules/email'
import blocks from './modules/blocks'
import images from './modules/images'
import orders from './modules/orders'
import webhook from './modules/webhook'
import project from './modules/project'
import folders from './modules/folders'
import request from './modules/request'
import projects from './modules/projects'
import sections from './modules/sections'
import products from './modules/products'
import feedbacks from './modules/feedbacks'
import orderform from './modules/order-form'
import order_term from './modules/order_term'
import allProjects from './modules/allProjects'
import attachments from './modules/attachments'
import productform from './modules/productForm'
import file_upload from './modules/file_upload'
import order_types from './modules/order_types'
import collaborators from './modules/collaborators'
import notifications from './modules/notifications'
import pageTemplates from './modules/pageTemplates'
import brand_profile from './modules/brand_profile'
import log_histories from './modules/log_histories'
import requestmessage from './modules/requestMessage'
import generalSettings from './modules/generalSettings'
import projectTemplates from './modules/projectTemplates'
import canned_responses from './modules/canned_responses'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
    view: 'build',
    success: null,
    preview: false,
    imageUrl: null,
    updating: false,
    showImageDialog: false,
  },

  mutations: {
    setViewState(state, type) {
      state.view = type
    },

    setSuccess(state, message) {
      state.success = message

      setTimeout(() => {
        state.success = null
      }, 3000)
    },

    setError(state, message) {
      state.error = message

      setTimeout(() => {
        state.error = null
      }, 3000)
    },

    updatingState(state, bol) {
        state.updating = bol
    },

    previewMode(state, bol) {
      state.preview = bol
    },

    removeObject(state, payload) {
      let index = payload.collection.find(c => c.id == payload.object.id)
      if (index) payload.collection.splice(payload.collection.indexOf(index), 1)
    },

    showImagePreview(state, url) {
      state.imageUrl = url
      state.showImageDialog = true
    },
    
    closeImagePreview(state) {
      state.imageUrl = null
      state.showImageDialog = false
    },

    /*------------------------------------------------------------------------------
     * @params
     *
     * Object
     *  Object object
     *  String field
     *  String any
     *----------------------------------------------------------------------------*/
    updateObjectField(state, payload) {
      Vue.set(payload.object, payload.field, payload.value)
    }
  },

  actions: {
    showSuccess({ commit }, message) {
      commit('setSuccess', message)
    },

    showError({ commit }, message) {
      commit('setError', message)
    },

    showUpdating({ commit }, bol) {
      commit('updatingState', bol)
    }
  },
  modules: {
    user,
    page,
    copy,
    users,
    notes,
    types,
    email,
    blocks,
    images,
    orders,
    project,
    folders,
    request,
    webhook,
    projects,
    sections,
    products,
    orderform,
    feedbacks,
    order_term,
    file_upload,
    allProjects,
    attachments,
    productform,
    order_types,
    collaborators,
    notifications,
    pageTemplates,
    brand_profile,
    log_histories,
    requestmessage,
    generalSettings,
    projectTemplates,
    canned_responses,
  }
})
